$.ajaxSetup({
  crossDomain: false,
  beforeSend: function(xhr, settings) {
    var token;

    if (!/^(GET|HEAD|OPTIONS|TRACE)$/.test(settings.type)) {
      token = $("input[name=csrfmiddlewaretoken]").val();
      if (token) {
        xhr.setRequestHeader("X-CSRFToken", token);
      } else {
        if (typeof console !== "undefined" && console !== null) {
          if (typeof console.warn === "function") {
            console.warn("CSRF token not found");
          }
        }
      }
    }
    return true;
  }
});
