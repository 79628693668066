import debounce from "./debounce";

const resizeBrandBlocks = () => {
  Array.prototype.map.call(document.querySelectorAll(".brand-block"), (el) => {
    const productId = el.dataset.previousProductId;
    const productLink = document.querySelector(
      `.product-link[data-product-id="${productId}"]`,
    );
    if (productLink) {
      const height = productLink.offsetHeight;
      el.style.height = `${height}px`; // eslint-disable-line
    }
  });
};

// resize brand blocks
window.addEventListener("resize", debounce(resizeBrandBlocks, 100));
resizeBrandBlocks();

export default resizeBrandBlocks;
