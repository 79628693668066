(function($) {
  $.fn.connectDataLinks = function() {
    return this.each(function() {
      $(this).click(function() {
        document.location.href = $(this).data("link");
      });
    });
  };

  $("*[data-link]").connectDataLinks();
})(jQuery);
