/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable no-new */
/* eslint-disable func-names */
import Typed from "typed.js";
import { getFSImageUrl, getImageDimentionsForElement } from "./common/helpers";

(function ($) {
  $(".typed").each(function () {
    const typedTarget = $(this).find(".typed-target");
    const typedStrings = $(this).find(".typed-strings");
    new Typed(typedTarget[0], {
      stringsElement: typedStrings[0],
      typeSpeed: 60,
      startDelay: 3000,
      backDelay: 3000,
      showCursor: true,
      fadeOut: true,
    });
  });

  $.fn.doLoader = function () {
    const $bar = $("<div/>").attr("class", "page-loading-bar");
    const timing = Math.random() * 5000 + 3000;
    $bar.css({
      width: "10%",
      transition: `all ${timing}ms cubic-bezier(0.190, 1.000, 0.220, 1.000)`,
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 99999,
      height: "2px",
      background: "#48bc42",
    });
    this.append($bar);
    setTimeout(() => {
      $bar.css("width", "100%");
    }, 10);
  };
  $.fn.hideLoader = function () {
    this.find(".page-loading-bar").remove();
  };

  $(window).on("beforeunload", () => {
    $("body").doLoader();
  });

  $.fn.serializeObject = function () {
    const o = {};
    const a = this.serializeArray();
    $.each(a, function () {
      if (o[this.name] !== undefined) {
        if (!o[this.name].push) {
          o[this.name] = [o[this.name]];
        }
        o[this.name].push(this.value || "");
      } else {
        o[this.name] = this.value || "";
      }
    });
    return o;
  };

  $.fn.isTopOnScreen = function () {
    const element = this.get(0);
    const bounds = element.getBoundingClientRect();
    return bounds.top < window.innerHeight && bounds.top > 0;
  };

  $.fn.preloadBg = function () {
    this.each((i, el) => {
      const $el = $(el);
      const dimentions = getImageDimentionsForElement($el[0]);
      const imgUrl = getFSImageUrl(
        $el.data("image-fsid"),
        dimentions.width,
        dimentions.height,
      );

      // not using i = new Image() because the onload behaviour is erratic
      // https://bugs.chromium.org/p/chromium/issues/detail?id=7731#c12
      const $i = $("<img>");
      $i.on("load", () => {
        $el
          .css({
            "background-image": `url(${imgUrl})`,
          })
          .removeClass("preloading preloading-bg");
      })
        .attr("src", imgUrl)
        .css("display", "none")
        .appendTo($("body"));
    });
  };
  $(".preloading-bg").preloadBg();
}(jQuery));

window.pad = function (num, size) {
  let s = `${num}`;
  while (s.length < size) s = `0${s}`;
  return s;
};

window.scrollToId = function (aid, scrollContainerSelector) {
  if (typeof scrollContainerSelector === "undefined") {
    scrollContainerSelector = "html,body";
  }
  if ($(scrollContainerSelector).length === 0) return;
  const $scrollContainerSelector = $(scrollContainerSelector);
  const aTag = $(aid);

  let targetScrollTop;
  if (scrollContainerSelector === "html,body") {
    targetScrollTop = aTag.offset().top - 70;
  } else {
    targetScrollTop = aTag[0].offsetTop - 15;
  }

  $scrollContainerSelector.animate(
    {
      scrollTop: targetScrollTop,
    },
    "fast",
  );
};

(function ($) {
  $(".jumbotron-carousel").slick({
    autoplay: true,
    autoplaySpeed: 8000,
    speed: 3000,
    fade: true,
    arrows: false,
    dots: false,
  });

  $(".jumbotron-carousel-slide").each((i, el) => {
    // load in the background image according to the data-image-fsid attr
    // then load in an appropriate size for the screen (to the nearest 300)
    const dimentions = getImageDimentionsForElement(el, 300);
    const imgUrl = getFSImageUrl(
      $(el).data("image-fsid"),
      dimentions.width,
      dimentions.height,
    );
    $(el).css({ backgroundImage: `url('${imgUrl}')` });
  });

  $("time.timeago").timeago();

  /*
  Turn our plain HTML into tabbable content using the H3 tags as the tab.
  */
  $(".autotab .tab-content h3").each(function (i) {
    const $this = $(this);
    const title = $this.html();
    $this
      .nextUntil("h3")
      .andSelf()
      .wrapAll(`<div class='tab-pane fade' id='part${i}'/>`);
    $(".autotab .nav-pills").append(
      `<li><a href='#part${i}' data-toggle='tab'>${title}</a></li>`,
    );
    $(".autotab .nav-pills a:first").tab("show");
  });

  $("*[data-action=\"toggle-element\"]").click(function (e) {
    e.preventDefault();
    const targetElement = $($(this).attr("href"));
    targetElement.slideToggle();
  });

  $("a.scroll-link").click(function (e) {
    e.preventDefault();
    window.scrollToId($(this).attr("href"));
  });

  $("*[data-scroll-to]").click(function () {
    window.scrollToId($(this).data("scroll-to"));
  });

  $(".press-icons img").popover();

  $(".sales-number").popover();

  $("[data-toggle=popover]").popover();
  $("[data-toggle=tooltip]").tooltip();

  $("body").on("click", (e) => {
    // only buttons
    if (
      $(e.target).data("toggle") !== "popover"
      && $(e.target).parents(".popover.in").length === 0
    ) {
      $("[data-toggle=\"popover\"]").popover("hide");
    }
    // buttons and icons within buttons
    /*
      if ($(e.target).data('toggle') !== 'popover'
          && $(e.target).parents('[data-toggle="popover"]').length === 0
          && $(e.target).parents('.popover.in').length === 0) {
          $('[data-toggle="popover"]').popover('hide');
      }
      */
  });

  $("#why-much-better").on("show.bs.collapse", () => {
    analytics.track("Show Why Much Better");
  });

  // NIFE HOMEPAGE SCRIPTS
  // TODO: Move this to a nife_home javascript file ?
  $(".trip-list li a").on("shown.bs.tab", (e) => {
    const $tab = $($(e.target).attr("href"));
    const $carousel = $tab.closest(".carousel");
    const $titleLink = $carousel.find(".title-right-link");

    $titleLink.fadeOut(250, () => {
      $titleLink
        .text($tab.data("view-more-text"))
        .attr("href", $tab.data("view-more-url"))
        .fadeIn(250);
    });
    const $carouselTitle = $carousel.find(".carousel-title");
    $carouselTitle.fadeOut(250, () => {
      $carouselTitle.text($tab.data("menu-title")).fadeIn(250);
    });
  });
  $(".trip-list li a").click(function () {
    const txt = $(this).text();
    $("#trip-type").fadeOut(250, () => {
      $("#trip-type").text(txt);
      $("#trip-type").fadeIn(250);
    });
  });

  $(".tab-collapse").tabCollapse();
}(jQuery));
