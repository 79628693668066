import {
  getFSImageUrl,
  preloadImages,
  getImageDimentionsForElement
} from "./helpers";

(function($) {
  var ImageSwicher = {
    currentImageIdx: 0,
    images: [],
    showSpinnerTimeout: null,

    init: function($el) {
      this.$el = $el;
      this.$ = function(selector) {
        return $el.find(selector);
      };
      this.images = this.buildImagesArray();
      if (this.images.length > 1) {
        this.addListeners();
      } else {
        this.$(".image-switcher-next, .image-switcher-prev").hide();
      }

      this.preloaded = false;
      this.showCurrentImage();
    },

    preloadImages: function() {
      if (this.preloading) return;
      preloadImages(this.images);
      this.preloading = true;
    },

    buildImagesArray: function() {
      const dimentions = getImageDimentionsForElement(this.$el[0]);
      const images = this.$el.data("images").split(",");
      let imageFSId;
      for (var i = images.length - 1; i >= 0; i--) {
        imageFSId = images[i];
        // if it's a URL like https://d36euxcbeiqfla.cloudfront.net/api/file/3rbKIylwRJKz5ho4DaEH
        // then we need to grab the last part.
        if (imageFSId.indexOf("/") >= 0) {
          imageFSId = imageFSId.split("/").pop();
        }
        images[i] = getFSImageUrl(
          imageFSId,
          dimentions.width,
          dimentions.height
        );
      }
      return images;
    },

    addListeners: function() {
      var that = this;
      this.$(".image-switcher-prev").on("click", function(e) {
        e.preventDefault();
        e.stopPropagation();
        that.showPrevImage();
      });
      this.$(".image-switcher-next").on("click", function(e) {
        e.preventDefault();
        e.stopPropagation();
        that.showNextImage();
      });
      this.$(".image-switcher-img").on("load", function() {
        that.hideLoader();
      });
    },

    showLoaderSpinner: function() {
      this.$(".image-switcher-loader>i").addClass(
        "fa-spin fa-refresh fa-3x"
      );
    },

    showLoader: function() {
      // var that = this;
      // // this is on a timeout so that it doesn't flicker
      // // if the loading is very quick. the timeout is cleared
      // // when hideLoader is called.
      // this.showSpinnerTimeout = setTimeout(
      //   function() {
      //     that.showLoaderSpinner()
      //   }, 500);
      // // show the loader
      // this.$('.image-switcher-loader')
      //   .removeClass('hide')
      //   .show()
    },

    hideLoader: function() {
      clearTimeout(this.showSpinnerTimeout);
      this.$(".image-switcher-loader>i").removeClass(
        "fa-spin fa-refresh fa-3x"
      );
      this.$(".image-switcher-loader").fadeOut("fast");
    },

    showCurrentImage: function() {
      this.showLoader();
      this.$el.css(
        "background-image",
        "url(" + this.images[this.currentImageIdx] + ")"
      );
      if (this.currentImageIdx == 0) {
        this.$el.css("background-size", "cover");
      } else {
        this.$el.css("background-size", "cover");
      }
    },

    showPrevImage: function() {
      this.preloadImages();
      this.currentImageIdx = this.getPrevImageIdx();
      this.showCurrentImage();
    },

    showNextImage: function() {
      this.preloadImages();
      this.currentImageIdx = this.getNextImageIdx();
      this.showCurrentImage();
    },

    getNextImageIdx: function() {
      var idx = this.currentImageIdx + 1;
      if (idx >= this.images.length) {
        idx = 0;
      }
      return idx;
    },

    getPrevImageIdx: function() {
      var idx = this.currentImageIdx - 1;
      if (idx < 0) {
        idx = this.images.length - 1;
      }
      return idx;
    }
  };

  $.fn.imageSwitcher = function() {
    return this.each(function() {
      var imageSwitcher = Object.create(ImageSwicher);
      imageSwitcher.init($(this));
    });
  };

  $(".image-switcher").imageSwitcher();
})(jQuery);
