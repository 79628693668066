(function($) {
    const url = document.location.toString();

    // Enable link to tab
    if (url.match('#')) {
        const activeTab = url.split('#')[1];
        $(`.nav-tabs-anchors a[href="#${activeTab}"]`).tab('show');
    }

    // Change hash on tab switch
    $('.nav-tabs-anchors a').on('shown.bs.tab', function (e) {
        const scrollTop = document.documentElement.scrollTop;

        window.location.hash = e.target.hash;
        window.scrollTo(0, scrollTop);
    })

})(jQuery);
