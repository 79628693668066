// Event Handlers
(function ($) {
  $(() => {
    // Customer question dialog
    if ($("#customer-question").length) {
      document.getElementById("js-customer-question").addEventListener(
        "customer-question.close",
        (e) => {
          analytics.track("customer-question.close", {
            question: e.detail,
            product: $("body").data("productId"),
          });
        },
        false,
      );

      document.getElementById("js-customer-question").addEventListener(
        "customer-question.contact",
        (e) => {
          analytics.track("customer-question.contact", {
            question: e.detail,
            product: $("body").data("productId"),
          });
        },
        false,
      );

      $("#customer-question").on("shown.bs.modal", () => {
        $("#customer-question-input").focus();
        analytics.track("customer-question.open", {
          product: $("body").data("productId"),
        });
      });
    }
  });
}(jQuery));
