const debounce = (fn, time) => {
  let timeout;

  // eslint-disable-next-line
  return function(...args) {
    const functionCall = () => fn.apply(this, args);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

export default debounce;
