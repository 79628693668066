(function($) {
  $(".company-popover").each(function(i, el) {
    $(el).popover({
      trigger: "click",
      html: true,
      content: function() {
        return $($(el).data("content-target")).html();
      }
    });
  });

  // hide popovers if you don't click on a popover link.
  $("body").on("click", function(e) {
    $(".company-popover").each(function() {
      //the 'is' for buttons that trigger popups
      //the 'has' for icons within a button that triggers a popup
      if (
        !$(this).is(e.target) &&
        $(this).has(e.target).length === 0 &&
        $(".popover").has(e.target).length === 0
      ) {
        $(this).popover("hide");
      }
    });
  });
})(jQuery);
