import FX from "./FX";

// only instantiate the window.fx object once, but let other modules import
// this as normal `import fx from "./forex";`

let _fx;
if (typeof window.fx !== "undefined") {
  _fx = window.fx;
} else {
  _fx = new FX();
  window.fx = _fx;
}
const fx = _fx;
export default fx;
