window.decodeQuerystring = function() {
  var qs = window.location.search.substring(1).split("&");
  var formData = {};
  qs.forEach(function(param) {
    var params = param.split("=");
    formData[params[0]] = decodeURIComponent(params[1]);
  });

  return {
    start_date: formData.start_date,
    duration: formData.duration,
    number_of_adults: formData.group_size,
    number_of_children: 0,
    minimum_bedrooms: Math.ceil(formData.group_size / 2),
    number_of_nights_flexible: formData.check_in_date_flexibility
  };
};

window.parseQueryString = function() {
  var qs = window.location.search.substring(1).split("&");
  var formData = {};
  qs.forEach(function(param) {
    var params = param.split("=");
    formData[params[0]] = decodeURIComponent(params[1]);
  });
  return formData;
};

(function(jQuery) {
  jQuery.fn.deserialize = function(data) {
    var f = jQuery(this),
      map = {},
      find = function(selector) {
        return f.is("form") ? f.find(selector) : f.filter(selector);
      };
    //Get map of values
    jQuery.each(data.split("&"), function() {
      var nv = this.split("="),
        n = decodeURIComponent(nv[0]),
        v = nv.length > 1 ? decodeURIComponent(nv[1]) : null;
      if (!(n in map)) {
        map[n] = [];
      }
      map[n].push(v);
    });
    //Set values for all form elements in the data
    jQuery.each(map, function(n, v) {
      find("[name='" + n + "']").val(v);
    });
    //Clear all form elements not in form data
    find("input:text,select,textarea").each(function() {
      if (!(jQuery(this).attr("name") in map)) {
        jQuery(this).val("");
      }
    });
    find("input:checkbox:checked,input:radio:checked").each(function() {
      if (!(jQuery(this).attr("name") in map)) {
        this.checked = false;
      }
    });
    return this;
  };
})(jQuery);
