window.addEventListener('DOMContentLoaded', function() {

    const purposes = $('.consent-purpose-checkbox').map(function() {
        return $(this).attr('name')
    }).get();

    if(purposes && purposes.length > 0) {

        let purposesApp = new PurposesApp(purposes);

        purposesApp.readPurposes().then(({ names }) => {
            names.map(purposeName => {
                $(`.consent-purpose-checkbox[name="${purposeName}"]`).attr('checked', true)
            });
        });

        $('.consent-purpose-save').on('click', function() {
            const checkedPurposes = $('.consent-purpose-checkbox:checked').map(function() {
                return $(this).attr('name');
            }).get();

            const emptyPurposes = $('.consent-purpose-checkbox:not(:checked)').map(function() {
                return $(this).attr('name');
            }).get();

            if(checkedPurposes.length > 0 || emptyPurposes.length > 0) {
                $('.consent-purpose-save')
                    .attr('disabled', true)
                    .addClass('btn-disabled')
                    .text('Saving...');

                try {
                    if(checkedPurposes.length > 0) {
                        purposesApp.grantPurposes(checkedPurposes).then(() => {
                            $('.consent-purpose-save').text('Saved!');
                        });
                    }

                    if(emptyPurposes.length > 0) {
                        purposesApp.revokePurposes(emptyPurposes).then(() => {
                            $('.consent-purpose-save').text('Saved!');
                        });
                    }

                    setTimeout(() => {
                        $('.consent-purpose-save')
                            .text('Save')
                            .removeClass('btn-disabled')
                            .attr('disabled', false);
                    }, 2000);

                } catch(e) {
                    $('.consent-purpose-save')
                            .text('Save')
                            .removeClass('btn-disabled')
                            .attr('disabled', false);
                }

            }
        });

    }



});


class PurposesApp {
    constructor(purposes = []) {
        this.purposes = purposes;

        this.readPurposes = this.readPurposes.bind(this);
        this.grantPurposes = this.grantPurposes.bind(this);
        this.revokePurposes = this.revokePurposes.bind(this);
    }

    readPurposes() {
        return this.makeRequest('read', JSON.stringify({ names: this.purposes }));
    }

    grantPurposes(names) {
        return this.makeRequest('grant', JSON.stringify({ names }));
    }

    revokePurposes(names) {
        return this.makeRequest('revoke', JSON.stringify({ names }));
    }

    makeRequest(type, body) {
        return fetch(`/api/v3/consent/user_consents/${type}/`, {
            method: "POST",
            credentials: "include",
            headers: new Headers({
                "X-CSRFToken": Cookies.get('csrftoken'),
                "Content-Type": "application/json"
            }),
            body
        }).then(resp => resp.json())
    }
}

