/* eslint-disable no-param-reassign */
import EventEmitter from "eventemitter3";
import Cookies from "js-cookie";

const DEFAULT_USER_CURRENCY_CODE = "GBP";
const USER_CURRENCY_ENDPOINT = "/api/v3/accounts/profile/currency/";
const RATES_ENDPOINT = "/api/v2/forex/rates/";

const fetchUserCurrencyCode = () => fetch(USER_CURRENCY_ENDPOINT, {
  credentials: "include",
}).then(response => response.json());

const fetchRates = () => fetch(RATES_ENDPOINT, {
  credentials: "include",
}).then(response => response.json());

class FX extends EventEmitter {
  constructor() {
    super();
    this.code = undefined;
    this.rates = {};
    this.ready = false;
    this.init();
  }

  init() {
    fetchRates()
      .then((rates) => {
        this.rates = rates;
      })
      .then(() => fetchUserCurrencyCode())
      .then((res) => {
        this.code = res.code;
      })
      .then(() => {
        this.updateSwitch();
        this.updateDisplay();
        this.addSwitchHandler();
        this.ready = true;
        this.emit("init", this.code);
        this.emit("change", this.code);
      });
  }

  getUserCurrencyCode() {
    return this.code;
  }

  saveUserCurrencyCode(code) {
    fetch(USER_CURRENCY_ENDPOINT, {
      method: "POST",
      credentials: "include",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code }),
    }).then(() => this.emit("saved", this.code));
  }

  setUserCurrency(currencyCode) {
    /*  Check the currencyCode is supported (or undefined),
          if not, use the default
      */
    let cc = currencyCode;
    if (cc === undefined) cc = this.code;

    if (
      cc === undefined
      || !Object.prototype.hasOwnProperty.call(this.rates, cc)
    ) {
      cc = DEFAULT_USER_CURRENCY_CODE;
    }

    this.code = cc;
    this.updateSwitch();
    this.updateDisplay();
    this.emit("change", this.code);
    this.saveUserCurrencyCode(this.code);
  }

  buy(amount, from, to) {
    return amount / this.rates[from][to];
  }

  addSwitchHandler() {
    // add click handler for currency switchers
    Array.prototype.forEach.call(
      document.querySelectorAll("[data-toggle=currency]"),
      (el) => {
        el.addEventListener("click", (e) => {
          e.preventDefault();
          const { currencyCode } = e.currentTarget.dataset;
          this.setUserCurrency(currencyCode);
        });
      },
    );
  }

  renderPrice(price, currencyCode, round = false) {
    /*
      converts the price from the currencyCode to the user currency,
      rounds it and formats it using toLocaleString then removes the
      decimal places that toLocaleString puts in.
      */
    if (!this.ready) return "";

    const renderedPrice = Math.round(
      this.buy(price, this.code, currencyCode),
    ).toLocaleString("en", {
      currency: this.code,
      style: "currency",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });

    if (round) {
      return renderedPrice.slice(0, -3);
    }
    return renderedPrice;
  }

  updateSwitch() {
    const { code } = this;
    if (typeof code === "undefined") return;

    Array.prototype.forEach.call(
      document.querySelectorAll(".forex-label"),
      (el) => {
        el.innerText = this.code;
      },
    );
  }

  updateDisplay() {
    const { code } = this;
    if (typeof code === "undefined") return;

    Array.prototype.forEach.call(
      document.querySelectorAll(".price-block"),
      (el) => {
        let { amount } = el.dataset;
        const { currencyCode } = el.dataset;
        // handle an amount such as "TBC"
        if (
          amount === undefined
          || amount === ""
          || Number.isNaN(Number(amount))
        ) {
          el.innerHTML = `<span>${amount}</span>`;
        } else {
          // detect the number of decimal places so that they can be
          // reapplied to the new amount later
          let decimalPlaces = 0;
          try {
            decimalPlaces = amount.toString().split(".")[1].length;
          } catch (e) {
            // do nothing
          }

          const fromCurrencyCode = code;

          amount = parseFloat(amount);
          let convertedAmount = amount;

          if (fromCurrencyCode !== currencyCode) {
            try {
              convertedAmount = this.buy(
                amount,
                fromCurrencyCode,
                currencyCode,
              );
            } catch (e) {
              // FX throws and error if it doesn't have the from/to
              // currency codes, in this case, move on and don't convert.
              // Could happen when a product has a currency not supported
              // in the frontend for some reason
              return;
            }
          }

          // toLocaleString doesn't seem to work correctly in older browsers.
          // Thus, we must add in an additional rounding step.
          const roundingFactor = 10 ** decimalPlaces;
          convertedAmount = Math.round(convertedAmount * roundingFactor) / roundingFactor;

          convertedAmount = convertedAmount.toLocaleString("en-GB", {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
            style: "currency",
            currency: fromCurrencyCode,
          });

          el.innerText = convertedAmount;
        }
      },
    );
  }
}
export default FX;
