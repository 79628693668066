(function($) {
  function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  function setupCustomDateInput($el) {
    var $input = $el.find("input");
    $input.data("value", $input.val());
    $input.val("");
    var $picker = $input.pickadate({
      format: "dd mmm. yyyy",
      hiddenName: true,
      formatSubmit: "yyyy-mm-dd",
      editable: false,
      min: Date()
    });
    $el.removeClass("hide");

    $el.on("click", function(e) {
      e.stopPropagation();
      $picker.pickadate("open");
    });
  }

  var CustomSelect = {
    init: function($el) {
      this.$ = function(selector) {
        return $el.find(selector);
      };
      var val = getParameterByName(this.$("select").attr("name"));
      if (val) {
        this.$("select").val(val);
      }
      this.updateDisplay();
      this.addListeners();
    },

    addListeners: function() {
      this.$("select").on("change", this.updateDisplay.bind(this));
    },

    updateDisplay: function() {
      var textValue = this.$("select option:selected").text();
      textValue = textValue.replace(/\([0-9]+\)/, "");
      this.$(".custom-input-display").text(textValue);

      if (
        this.$("select").val() != "" &&
        this.$("select").attr("name") == "location"
      ) {
        $("#location-name").text(textValue);
      }
    }
  };

  $.fn.customSelect = function() {
    return this.each(function() {
      var customSelect = Object.create(CustomSelect);
      customSelect.init($(this));
    });
  };

  $.fn.customDateInput = function() {
    return this.each(function() {
      setupCustomDateInput($(this));
    });
  };

  $(".custom-select").customSelect();
  $(".custom-date-input").customDateInput();
})(jQuery);
