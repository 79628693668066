/* global $ jQuery analytics Raven */
import initImages from "./common/imageloader";
import resizeBrandBlocks from "./common/resize-brand-blocks";
import fx from "./forex";

function getListId(i, $el) {
  // workout a unique listId, either the container id or
  // the preset-search-results number on the page
  const listId = $el.attr("id") || `list-${i}`;
  return `${document.location.pathname}#${listId}`;
}

function getProductObj(idx, el) {
  /* Return an object representing a product in a list */

  const imageUrl = $(el).find(".product-image")[0].dataset.image;

  const absoluteUrl = document.location.href.replace(
    document.location.pathname,
    el.dataset.productLink
  );

  return {
    product_id: el.dataset.productId,
    name: el.dataset.productName,
    brand: el.dataset.companyName,
    position: idx + 1,
    category: el.dataset.productActivity,
    url: absoluteUrl,
    image_url: imageUrl,
  };
}

function trackPresetSearchResultsView(i, $el) {
  // build up our product list:
  const products = [];
  $el.find(".product-link").each((idx, el) => {
    products.push(getProductObj(idx, el));
  });

  analytics.track("Product List Viewed", {
    list_id: getListId(i, $el),
    category: "Embedded Search Results",
    products,
  });
}

function setupProductClickHandlers(i, $el) {
  const listId = getListId(i, $el);
  $el.find(".product-link").each((idx, el) => {
    const productObj = getProductObj(idx, el);
    productObj.list_id = listId;

    // track the clicks on regular a tags using built in analytics.trackLink
    $(el)
      .find("a")
      .each((_, aEl) => {
        analytics.trackLink(aEl, "Product Clicked", productObj);
      });

    // track the clicks on our data-links
    $(el)
      .find("*[data-link]")
      .each((_, dataLinkEl) => {
        $(dataLinkEl).click(() => {
          analytics.track("Product Clicked", productObj, {}, () => {
            document.location.href = $(dataLinkEl).data("link");
          });
        });
      });
  });
}

function init($) {
  $(".preset-search-results").each((i, el) => {
    const $el = $(el);
    const format = $el.data("format") || "_blank";
    const pageSize = $el.data("page-size") || 5;
    const url = window.parseUri($el.data("search-url"));

    // Only load pages that are /adventures/discover/ or /ski/search/
    if (
      !(url.path === "/adventures/discover/" || url.path === "/ski/search/")
    ) {
      return;
    }

    $el.load(`${url.path}?${url.query}&${format}=&n=${pageSize}`, () => {
      fx.updateDisplay();
      initImages();
      window.initShortlist();
      resizeBrandBlocks();
      setupProductClickHandlers(i, $el);
      trackPresetSearchResultsView(i, $el);
      if ($el.hasClass("slick-carousel-3")) {
        if ($el.hasClass("slick-initialized")) {
          $el.slick("unslick");
        }
        $el.slick({
          dots: false,
          arrows: true,
          infinite: false,
          speed: 300,
          slidesToShow: 3,
          slidesToScroll: 3,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
              },
            },
          ],
        });
      }
    });
  });
}

document.addEventListener("DOMContentLoaded", () => {
  init(jQuery);
});
