export const getFSImageUrl = (
  fsid,
  width,
  height,
  fit = "crop",
  quality = 70,
  baseUrl = null,
) => {
  const baseUrlOrDefault = baseUrl || window.FILESTACKCDN_BASE_URL;
  return `${baseUrlOrDefault}${fsid}/${width}/${height}/${fit}/${quality}/`;
};

export const getContentfulImageUrl = (
  baseUrl,
  width,
  height,
  fit = "fill",
  quality = 70,
) =>
  `${baseUrl}?w=${width}&h=${height}&fit=${fit}&q=${quality}&fm=jpg&fl=progressive`;

const _preloadImages = (imageArray, index, callback) => {
  index = index || 0;
  if (imageArray && imageArray.length > index) {
    const img = new Image();
    img.onload = function () {
      _preloadImages(imageArray, index + 1, callback);
    };
    img.src = imageArray[index];
  } else if (callback) callback();
};

export const preloadImages = (imageArray, callback) => {
  _preloadImages(imageArray, 0, callback);
};

export const getImageDimentionsForElement = (
  element,
  precision = 100,
  maxWidth = 1600,
) => {
  let height = Math.ceil(parseInt(element.offsetHeight, 10) / precision) * precision;
  let width = Math.ceil(parseInt(element.offsetWidth, 10) / precision) * precision;
  if (width > maxWidth) {
    height = parseInt(height * (maxWidth / width), 10);
    width = maxWidth;
  }
  return {
    width,
    height,
  };
};
