/**
 * Adds a screen-width dependent background image to the target element.
 * If the target is empty, it must have a height set in order to display the bg.
 * 
 * Use the following html attributes to pass in the corresponding image url:
 * data-image-desktop, data-image-mobile, data-image-tablet
 * 
 * The default break points are: >400 for tablet, >768 for desktop
 * These can be overridden by the following attributes:
 * data-width-tablet, data-width-desktop
 * 
 * @param {node} targetEl The container element for the background image
 */
window.dynamicBackgroundImage = function(targetEl) {
    const clientWidth = document.documentElement.clientWidth;
    const defaultWidths = {
        desktop: 768,
        tablet: 400
    };

    const { 
        imageDesktop, 
        imageMobile, 
        imageTablet,
        widthDesktop,
        widthTablet 
    } = targetEl.dataset;
    
    let imageUrl = `url(${imageDesktop})`;

    if(clientWidth < (widthTablet || defaultWidths.tablet) && imageMobile !== undefined) {
        imageUrl = `url(${imageMobile})`;
    } else if(clientWidth < (widthDesktop || defaultWidths.desktop) && imageTablet !== undefined) {
        imageUrl = `url(${imageTablet})`;
    }
    
    targetEl.style.backgroundImage = imageUrl;
    targetEl.style.backgroundSize = 'cover';
    targetEl.style.backgroundPosition = 'center';
}

// Apply dynamic bg to all elements that has data-dynamic-bg attribute
const targets = document.querySelectorAll('[data-dynamic-bg]');

for(let i=0; i<targets.length; i++) {
    window.dynamicBackgroundImage(targets[i]);
}