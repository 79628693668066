/* eslint no-param-reassign: 0 */
import { getContentfulImageUrl, getFSImageUrl } from "./helpers";

const getResizeUrl = (imageUrl, el) => {
  const width = el.dataset.width || 800;
  const height = el.dataset.height || 600;

  if (imageUrl.indexOf("ctfassets") > 0) {
    return getContentfulImageUrl(imageUrl, width, height);
  }
  const imageFSId = imageUrl.indexOf("/") >= 0 ? imageUrl.split("/").pop() : imageUrl;
  return getFSImageUrl(imageFSId, width, height);
};

const initImages = () => {
  // Load product card images
  const productImages = document.querySelectorAll(".product-image");

  [].forEach.call(productImages, (el) => {
    const url = el.dataset.image;
    if (url) {
      const resizeUrl = getResizeUrl(url, el);
      el.innerHTML = `
        <img
          loading="lazy"
          src="${resizeUrl}" alt=""
          width="100%"
          height="100%"
          style="object-fit:cover">
      `.trim();
    }
  });

  // Load explore page content block images
  const contentBlockImages = document.querySelectorAll(
    ".page-sidebar-image .img-placeholder",
  );
  const screenWidth = window.innerWidth || document.documentElement.clientWidth;

  [].forEach.call(contentBlockImages, (el) => {
    const squareImage = el.dataset.imageSquare;
    const letterboxImage = el.dataset.imageLetterbox;

    if (squareImage && letterboxImage) {
      let imageUrl = squareImage;

      if (screenWidth >= 1024) {
        imageUrl = letterboxImage;
      }

      el.style.backgroundImage = `url(${imageUrl})`;
    }
  });
};

window.initImages = initImages;

export default initImages;

initImages();
